import React from 'react';

const SplashScreenAnimation: React.FC = () => {
  const css = `#etPrSvmdDep3_to {
  animation: etPrSvmdDep3_to__to 3000ms linear 1 normal forwards;
}
@keyframes etPrSvmdDep3_to__to {
  0% { transform: translate(260px,520px); }
  0.333333% { 
    transform: translate(260px,520px);
    animation-timing-function: cubic-bezier(0.19,1,0.22,1);
  }
  12.333333% { transform: translate(260px,280px); }
  100% { transform: translate(260px,280px); }
}

#etPrSvmdDep7_to {
  animation: etPrSvmdDep7_to__to 3000ms linear 1 normal forwards;
}
@keyframes etPrSvmdDep7_to__to {
  0% { transform: translate(360px,515px); }
  9.666667% {
    transform: translate(360px,515px);
    animation-timing-function: cubic-bezier(0.19,1,0.22,1);
  }
  21% { transform: translate(360px,335px); }
  100% { transform: translate(360px,335px); }
}

#etPrSvmdDep11_to {
  animation: etPrSvmdDep11_to__to 3000ms linear 1 normal forwards;
}
@keyframes etPrSvmdDep11_to__to {
  0% { transform: translate(160px,485px); }
  21.666667% {
    transform: translate(160px,485px);
    animation-timing-function: cubic-bezier(0.19,1,0.22,1);
  }
  31% { transform: translate(160px,365px); }
  100% { transform: translate(160px,365px); }
}

#etPrSvmdDep15_to {
  animation: etPrSvmdDep15_to__to 3000ms linear 1 normal forwards;
}
@keyframes etPrSvmdDep15_to__to {
  0% { transform: translate(260px,495px); }
  33.666667% {
    transform: translate(260px,495px);
    animation-timing-function: cubic-bezier(0.19,1,0.22,1);
  }
  43% { transform: translate(260px,435px); }
  100% { transform: translate(260px,435px); }
}

#etPrSvmdDep20_to {
  animation: etPrSvmdDep20_to__to 3000ms linear 1 normal forwards;
}
@keyframes etPrSvmdDep20_to__to {
  0% { transform: translate(259.81601px,640px); }
  44.333333% {
    transform: translate(259.81601px,640px);
    animation-timing-function: cubic-bezier(0.19,1,0.22,1);
  }
  54.333333% { transform: translate(259.81601px,587.917511px); }
  100% { transform: translate(259.81601px,587.917511px); }
}

#etPrSvmdDep20 {
  animation: etPrSvmdDep20_c_o 3000ms linear 1 normal forwards;
}
@keyframes etPrSvmdDep20_c_o {
  0% { opacity: 0; }
  44.333333% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.19,1,0.22,1);
  }
  54.333333% { opacity: 1; }
  100% { opacity: 1; }
}`;
  return (
    <svg
      id="etPrSvmdDep1"
      viewBox="0 0 520 700"
      width="100%"
      height="100%"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <style>{css}</style>
      <g id="etPrSvmdDep3_to" transform="translate(260,520)">
        <g transform="translate(-260,-280)">
          <path d="M360,110L260,160v340h100v-390Z" fill="#86b2a5" />
          <path d="M160,110l100,50v340h-100v-390Z" fill="#187770" />
          <path d="M260,60l100,50-100,50-100-50L260,60Z" fill="var(--mui-palette-background-default)" />
        </g>
      </g>
      <g id="etPrSvmdDep7_to" transform="translate(360,515)">
        <g transform="translate(-360,-335)">
          <path d="M460,220L360,270v230h100v-280Z" fill="#86b2a5" />
          <path d="M260,220l100,50v230h-100v-280Z" fill="#187770" />
          <path d="M360,170l100,50-100,50-100-50l100-50Z" fill="var(--mui-palette-background-default)" />
        </g>
      </g>
      <g id="etPrSvmdDep11_to" transform="translate(160,485)">
        <g transform="translate(-160,-365)">
          <path d="M260,280L160,330v170h100v-220Z" fill="#86b2a5" />
          <path d="M60,280l100,50v170h-100v-220Z" fill="#187770" />
          <path d="M160,230l100,50-100,50L60,280l100-50Z" fill="var(--mui-palette-background-default)" />
        </g>
      </g>
      <g id="etPrSvmdDep15_to" transform="translate(260,495)">
        <g transform="translate(-260,-435)">
          <path d="M360,390L260,440v90l100-50v-90Z" fill="#86b2a5" />
          <path d="M160,390l100,50v90L160,480v-90Z" fill="#187770" />
          <path d="M260,340l100,50-100,50-100-50l100-50Z" fill="var(--mui-palette-background-default)" />
        </g>
      </g>
      <path d="M0,700v-330L260,500L520,370v330h-520Z" fill="var(--mui-palette-background-default)" />
      <g id="etPrSvmdDep20_to" transform="translate(259.81601,640)">
        <g id="etPrSvmdDep20" transform="translate(-259.81601,-587.917511)" opacity="0">
          <path
            d="M0,638.21v-78.78h13.3066v24.961l-1.7812-.106c.2794-5.757,1.4669-10.602,3.5624-14.534c2.0955-4.002,4.9245-6.986,8.4869-8.952c3.5624-2.036,7.5788-3.055,12.0493-3.055c3.9815,0,7.5788.738,10.792,2.212c3.283,1.475,6.077,3.546,8.3821,6.214c2.3749,2.598,4.1561,5.687,5.3436,9.268c1.2573,3.511,1.886,7.373,1.886,11.586v2.422c0,4.143-.5937,8.04-1.7812,11.691-1.1875,3.581-2.9338,6.705-5.2388,9.373-2.2353,2.668-4.9944,4.775-8.2774,6.319-3.2131,1.475-6.9152,2.212-11.1063,2.212-4.3308,0-8.2424-.877-11.735-2.633-3.4227-1.825-6.1818-4.564-8.2773-8.215-2.0955-3.721-3.2481-8.426-3.4576-14.113l4.6101,6.319v37.811L0,638.21Zm30.9091-33.282c2.8639,0,5.3436-.702,7.4391-2.106c2.1654-1.405,3.8418-3.37,5.0293-5.898s1.7812-5.442,1.7812-8.742-.5938-6.179-1.7812-8.636c-1.1875-2.458-2.829-4.353-4.9245-5.687-2.0956-1.405-4.6102-2.107-7.5439-2.107-2.5147,0-4.8896.597-7.1248,1.791-2.2353,1.193-4.0514,2.913-5.4484,5.16-1.3272,2.177-1.9908,4.845-1.9908,8.005v3.897c0,3.019.6985,5.617,2.0956,7.793c1.4668,2.107,3.3179,3.722,5.5531,4.845s4.5403,1.685,6.9153,1.685Z"
            fill="#187770"
          />
          <path
            d="M73.671,617.145v-57.715h13.3066v24.75h-.3143c0-8.145,1.7113-14.429,5.134-18.852c3.4926-4.424,8.5217-6.636,15.0877-6.636h2.2v14.535h-4.191c-4.61,0-8.1722,1.263-10.6868,3.791-2.5147,2.458-3.772,6.039-3.772,10.743v29.384h-16.7642Z"
            fill="#187770"
          />
          <path
            d="M145.44,619.147c-5.03,0-9.5-.773-13.412-2.318-3.911-1.614-7.229-3.791-9.954-6.529-2.654-2.739-4.68-5.863-6.077-9.374-1.397-3.581-2.095-7.337-2.095-11.269v-2.423c0-4.002.698-7.793,2.095-11.374c1.467-3.651,3.563-6.846,6.287-9.585c2.794-2.738,6.147-4.879,10.059-6.424c3.911-1.615,8.277-2.423,13.097-2.423c4.959,0,9.36.808,13.201,2.423c3.912,1.545,7.23,3.686,9.954,6.424c2.724,2.739,4.82,5.934,6.287,9.585c1.467,3.581,2.2,7.372,2.2,11.374v2.423c0,3.932-.698,7.688-2.095,11.269-1.397,3.511-3.458,6.635-6.182,9.374-2.655,2.738-5.938,4.915-9.849,6.529-3.912,1.545-8.417,2.318-13.516,2.318Zm0-14.219c3.283,0,6.007-.702,8.172-2.106c2.166-1.405,3.807-3.37,4.925-5.898c1.187-2.528,1.781-5.336,1.781-8.426c0-3.3-.594-6.179-1.781-8.636-1.188-2.528-2.899-4.529-5.134-6.003-2.166-1.475-4.82-2.212-7.963-2.212-3.074,0-5.728.737-7.963,2.212-2.236,1.474-3.947,3.475-5.134,6.003-1.118,2.457-1.677,5.336-1.677,8.636c0,3.09.559,5.898,1.677,8.426c1.117,2.528,2.794,4.493,5.029,5.898c2.235,1.404,4.924,2.106,8.068,2.106Z"
            fill="#187770"
          />
          <path
            d="M188.782,638.21v-78.78h13.306v24.961l-1.781-.106c.28-5.757,1.467-10.602,3.563-14.534c2.095-4.002,4.924-6.986,8.487-8.952c3.562-2.036,7.578-3.055,12.049-3.055c3.981,0,7.579.738,10.792,2.212c3.283,1.475,6.077,3.546,8.382,6.214c2.375,2.598,4.156,5.687,5.344,9.268c1.257,3.511,1.886,7.373,1.886,11.586v2.422c0,4.143-.594,8.04-1.782,11.691-1.187,3.581-2.933,6.705-5.238,9.373-2.236,2.668-4.995,4.775-8.278,6.319-3.213,1.475-6.915,2.212-11.106,2.212-4.331,0-8.243-.877-11.735-2.633-3.423-1.825-6.182-4.564-8.277-8.215-2.096-3.721-3.249-8.426-3.458-14.113l4.61,6.319v37.811h-16.764Zm30.909-33.282c2.864,0,5.344-.702,7.439-2.106c2.165-1.405,3.842-3.37,5.029-5.898c1.188-2.528,1.782-5.442,1.782-8.742s-.594-6.179-1.782-8.636c-1.187-2.458-2.829-4.353-4.924-5.687-2.096-1.405-4.61-2.107-7.544-2.107-2.515,0-4.89.597-7.125,1.791-2.235,1.193-4.051,2.913-5.448,5.16-1.327,2.177-1.991,4.845-1.991,8.005v3.897c0,3.019.699,5.617,2.096,7.793c1.466,2.107,3.317,3.722,5.553,4.845c2.235,1.123,4.54,1.685,6.915,1.685Z"
            fill="#187770"
          />
          <path
            d="M262.453,617.145v-57.715h13.306v24.75h-.314c0-8.145,1.711-14.429,5.134-18.852c3.493-4.424,8.522-6.636,15.088-6.636h2.2v14.535h-4.191c-4.61,0-8.172,1.263-10.687,3.791-2.515,2.458-3.772,6.039-3.772,10.743v29.384h-16.764Z"
            fill="#187770"
          />
          <path
            d="M333.973,617.883c-5.798,0-10.478-.702-14.04-2.107-3.563-1.474-6.182-3.897-7.859-7.267-1.606-3.44-2.41-8.074-2.41-13.902v-50.765h15.612v51.397c0,2.738.699,4.844,2.096,6.319c1.467,1.404,3.527,2.106,6.182,2.106h8.486v14.219h-8.067Zm-33.005-46.131v-12.322h41.072v12.322h-41.072Z"
            fill="#187770"
          />
          <path
            d="M352.685,640v-14.324h11.945c1.816,0,3.352-.245,4.61-.737c1.257-.421,2.27-1.158,3.038-2.212.769-1.053,1.397-2.492,1.886-4.318l14.145-58.979h15.926l-16.031,62.35c-1.117,4.493-2.689,8.074-4.715,10.742-1.956,2.668-4.61,4.564-7.963,5.688-3.283,1.193-7.474,1.79-12.573,1.79h-10.268Zm18.022-24.118v-13.166h12.154v13.166h-12.154Zm-6.916,0L346.189,559.43h16.869l16.659,56.452h-15.926Z"
            fill="#187770"
          />
          <path d="M406.664,617.145v-18.852h18.44v18.852h-18.44Z" fill="#187770" />
          <path
            d="M472.614,617.145v-17.062h-2.829v-18.536c0-2.949-.698-5.161-2.095-6.635-1.398-1.475-3.633-2.212-6.706-2.212-1.537,0-3.563.035-6.077.105-2.515.071-5.099.176-7.754.316-2.654.141-5.064.281-7.229.422v-14.219c1.606-.14,3.492-.281,5.658-.421c2.165-.14,4.4-.246,6.705-.316s4.471-.105,6.496-.105c5.868,0,10.793.842,14.774,2.527c4.051,1.686,7.125,4.248,9.22,7.689c2.096,3.37,3.144,7.723,3.144,13.06v35.387h-13.307Zm-18.336,1.475c-4.121,0-7.753-.737-10.897-2.212-3.073-1.474-5.483-3.581-7.229-6.319-1.747-2.809-2.62-6.144-2.62-10.006c0-4.212,1.083-7.653,3.248-10.321c2.166-2.738,5.204-4.739,9.116-6.003c3.912-1.334,8.417-2.001,13.516-2.001h12.259v9.373h-12.364c-2.933,0-5.203.737-6.81,2.212-1.537,1.404-2.305,3.335-2.305,5.793c0,2.317.768,4.212,2.305,5.687c1.607,1.404,3.877,2.106,6.81,2.106c1.886,0,3.563-.316,5.03-.948c1.536-.702,2.794-1.86,3.772-3.475s1.536-3.862,1.676-6.741l3.982,4.213c-.35,4.002-1.328,7.373-2.934,10.111-1.537,2.738-3.702,4.845-6.496,6.319-2.725,1.475-6.077,2.212-10.059,2.212Z"
            fill="#187770"
          />
          <path
            d="M502.868,617.145v-57.715h16.764v57.715h-16.764Zm-7.648-45.288v-12.427h24.412v12.427h-24.412Z"
            fill="#187770"
          />
          <path
            d="M502.843,552.082c1.793,1.803,4.168,2.795,6.691,2.795s4.899-.992,6.692-2.795c3.689-3.708,3.689-9.743,0-13.453-1.793-1.801-4.17-2.794-6.692-2.794s-4.899.993-6.691,2.795c-3.69,3.708-3.69,9.744,0,13.452v0Zm6.984-14.33c1.58.057,3.102.616,4.347,1.596l-4.64,4.663-1.381-1.389c1.098-1.406,1.695-3.146,1.674-4.87v0Zm-3.028,3.51l-1.904-1.914c.894-.704,1.936-1.195,3.046-1.436-.015,1.176-.421,2.356-1.142,3.35Zm-1.347,1.336c-.964.662-2.106,1.012-3.273,1.005.246-1.058.716-2.05,1.379-2.909l1.894,1.904Zm-3.478,2.897c.073.002.146.013.219.013c1.665.006,3.286-.537,4.615-1.546l1.388,1.394-4.638,4.662c-1.001-1.296-1.556-2.883-1.584-4.523v0Zm11.634,2.611c.986-.721,2.158-1.13,3.32-1.147-.237,1.114-.721,2.161-1.417,3.06l-1.903-1.913Zm.565,3.258c-.853.671-1.841,1.148-2.895,1.399-.01-1.178.339-2.331,1.001-3.303l1.894,1.904v0Zm-4.773,1.604c-1.636-.026-3.218-.589-4.505-1.604l4.639-4.663l1.385,1.392c-1.044,1.374-1.57,3.107-1.519,4.875Zm7.688-7.905c-1.71-.017-3.438.581-4.834,1.682l-1.382-1.389l4.638-4.662c.968,1.255,1.52,2.783,1.578,4.369v0Z"
            fill="#187770"
          />
        </g>
      </g>
    </svg>
  );
};

export default SplashScreenAnimation;
